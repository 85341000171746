<template>
<section class="POMA">
   <div class="banner">
      <p :class="$i18n.locale == 'en' ? 'wrap' : 'nowrap'">{{ $t("PC.Product.POMA.sgfs7b") }}</p>
      <a :href="pomaUrl">{{ $t("PC.Product.POMA.rms9wl") }}</a>
      <router-link class="price" to="POMAPrice">{{
        $t("PC.Product.POMA.bumk5q")
      }}</router-link>
   </div>
   <section class="main_contain">
      <figure class="fg1">
         <p>{{ $t("PC.Product.POMA.75kpe2") }}</p>
         <figcaption>{{ $t("PC.Product.POMA.c15oic") }}</figcaption>
         <div class="box_contain">
            <img :src="src" alt="" />
            <article>
               <div class="box1" :style="stlye1">
                  <p>{{ $t("PC.Product.POMA.f84a1h") }}</p>
                  <span>{{ $t("PC.Product.POMA.732t2f") }}</span>
               </div>
               <div class="box1" :style="stlye2">
                  <p>{{ $t("PC.Product.POMA.oa6qtt") }}</p>
                  <span>{{ $t("PC.Product.POMA.jsz6xu") }}</span>
               </div>
               <div class="box1" :style="stlye3">
                  <p>{{ $t("PC.Product.POMA.k99vn8") }}</p>
                  <span>{{ $t("PC.Product.POMA.9x9qh6") }}</span>
               </div>
            </article>
         </div>
      </figure>
      <figure class="fg2">
         <figcaption>{{ $t("PC.Product.POMA.p2pydm") }}</figcaption>
         <article>
            <div>
               <img src="../../../assets/zh/images/poma_img4.jpg" alt="" />
               <i>{{ $t("PC.Product.POMA.1328mp") }}</i>
            </div>
            <div>
               <img src="../../../assets/zh/images/poma_img5.jpg" alt="" />
               <i>{{ $t("PC.Product.POMA.lwoc5e") }}</i>
            </div>
            <div>
               <img src="../../../assets/zh/images/poma_img6.jpg" alt="" />
               <i>{{ $t("PC.Product.POMA.zebirx") }}</i>
            </div>
            <div>
               <img src="../../../assets/zh/images/poma_img7.jpg" alt="" />
               <i>{{ $t("PC.Product.POMA.vaq89k") }}</i>
            </div>
            <div>
               <img src="../../../assets/zh/images/poma_img8.jpg" alt="" />
               <i>{{ $t("PC.Product.POMA.h5fbw8") }}</i>
            </div>
            <div>
               <img src="../../../assets/zh/images/poma_img9.jpg" alt="" />
               <i>{{ $t("PC.Product.POMA.t4h4oj") }}</i>
            </div>
         </article>
      </figure>
      <figure class="fg3">
         <img :src="$t('locale')=='zh'?suanfas:suanfas_en" alt="" />
      </figure>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "../components/FooterC";
let img1 = require("../../../assets/zh/images/poma_img1.jpg");
let img2 = require("../../../assets/zh/images/poma_img2.jpg");
let img3 = require("../../../assets/zh/images/poma_img3.jpg");

export default {
   components: {
      FooterC
   },
   data() {
      return {
         src: img1,
         stlye1: "background-color:#fbfbfd;border-bottom: 4Px solid #224386;",
         stlye2: "",
         stlye3: "",
         pomaUrl: Global.POMA_URL,
         suanfas: require('../../../assets/zh/images/suanfas_img10.png'),
         suanfas_en: require('../../../assets/en/images/suanfas_img10.png'),
      };
   },
   methods: {
      activing() {
         let activeBoxDom_list = document.getElementsByClassName("box1");
         console.log(activeBoxDom_list);
         for (let value of activeBoxDom_list) {
            value.onmouseenter = e => {
               if (e.target == activeBoxDom_list[0]) {
                  this.src = img1;
                  this.stlye1 =
                     "background-color:#fbfbfd;border-bottom: 4Px solid #224386;";
                  this.stlye2 = "";
                  this.stlye3 = "";
               } else if (e.target == activeBoxDom_list[1]) {
                  this.src = img2;
                  this.stlye2 =
                     "background-color:#fbfbfd;border-bottom: 4Px solid #224386;";
                  this.stlye3 = "";
                  this.stlye1 = "";
               } else if (e.target == activeBoxDom_list[2]) {
                  this.src = img3;
                  this.stlye3 =
                     "background-color:#fbfbfd;border-bottom: 4Px solid #224386;";
                  this.stlye1 = "";
                  this.stlye2 = "";
               }
            };
         }
      }
   },
   mounted() {
      this.activing();
   }
};
</script>

<style lang="scss" scoped>
.POMA {
   margin-top: 70Px;
   background-color: #fff;
   word-break: break-word !important;

   .banner {
      display: block;
      position: absolute;
      left: 50%;
      top: 70Px;
      transform: translate(-50%, 0);
      overflow: hidden;
      font-family:   PingFangSC-Semibold, PingFang SC;
      width: 1920Px;
      height: 440Px;
      background-image: url("../../../assets/zh/images/banner@2x.jpg");
      background-repeat: no-repeat;
      background-size: contain;

      p {
         display: -webkit-box;
         height: 56Px;
         font-size: 40Px;
         color: #ffffff;
         line-height: 56Px;
         position: absolute;
         top: 136Px;
         left: 480Px;
         letter-spacing: 4Px;
      }

      .wrap {
         width: 650Px;
         top: 106Px;
      }

      a {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 236Px;
         left: 480Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         border: 1Px solid #fff;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;

         &:hover {
            cursor: pointer;
         }
      }

      .price {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 236Px;
         left: 617Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         background: transparent;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;
         border: 1Px solid #ffffff;

         &:hover {
            cursor: pointer;
         }
      }
   }

   .main_contain {
      position: absolute;
      background-color: #fff;
      top: 510Px;
      width: 100%;

      figure.fg1 {
         margin: 0 auto;
         width: 960Px;
         background-color: #fff;
         margin-bottom: 100Px;

         &>p {
            width: 916Px;
            margin: 0 auto;
            font-size: 24Px;
            color: #333333;
            line-height: 58Px;
            padding-top: 72Px;
         }

         figcaption {
            padding: 72Px 0 32Px;
            font-size: 32Px;
            color: #333333;
            line-height: 45Px;
            text-align: center;
         }

         .box_contain {
            margin-bottom: 48Px;

            &>article {
               display: flex;

               div {
                  width: 320Px;
                  max-height: 291Px;
                  padding: 32Px 27Px 27Px;

                  p {
                     font-size: 24Px;
                     font-family: PingFangSC-Medium, PingFang SC;
                     color: #333333;
                     line-height: 40Px;
                     margin-bottom: 12Px;
                  }

                  span {
                     width: 100%;
                     font-size: 16Px;
                     font-family: PingFangSC-Regular, PingFang SC;
                     color: #666666;
                     line-height: 28Px;
                  }
               }
            }
         }
      }
   }

   figure.fg2 {
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 80Px;
      background-color: #F5F6F9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      figcaption {
         font-size: 32Px;
         color: #333333;
         line-height: 45Px;
         text-align: center;
         padding: 72Px 0 32Px;
      }

      article {
         display: flex;
         justify-content: center;
         flex-wrap: wrap;
         min-width: 1903Px;

         div {
            width: 284Px;
            height: 234Px;
            margin: 0 15Px;
            background-color: rosybrown;
            position: relative;
            margin-bottom: 20Px;

            i {
               line-height: 55Px;
               text-align: center;
               position: absolute;
               bottom: 0;
               width: 284Px;
               height: 55Px;
               background: rgba($color: #000, $alpha: 0.58);
               font-size: 18Px;
               font-family:   PingFangSC-Medium, PingFang SC;
               color: #ffffff;
            }
         }
      }

      @media only screen and (max-width: 1540Px) {
         article {
            width: 960Px;
            min-width: 960Px;
            margin: 0 auto;
         }
      }
   }

   figure.fg3 {
      width: 960Px;
      height: 650Px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 72Px auto 100Px;
   }
}
</style>
